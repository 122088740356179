import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not Found - JiggSwap" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn't exist... ):.</p>
  </>
)

export default NotFoundPage
